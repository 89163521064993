import $axios from '@/utils/requestvideo'

//获取视频管理文件列表
export function videolist (data) {
  const url = '/file/list'
  return $axios.fVideoPost(url, data)
}

//获取我的视频文件列表
export function videolistMy (data) {
  const url = '/file/listMy'
  return $axios.fVideoPost(url, data)
}


//大文件上传
export function fileUpload (data) {
  const url = '/bigfile/fileUpload'
  return $axios.fVideoPost(url, data)
}

//检查文件是否存在
export function uploadCheck (data) {
  const url = '/file/uploadCheck'
  return $axios.fVideoGet(url, data)
}

//文件下载
export function fileDownload (data) {
  const url = '/file/download'
  return $axios.fVideoGet(url, data)
}

//获取视频播放url
export function getBjyUrl (data) {
  const url = '/video/getBjyUrl'
  return $axios.fVideoGet(url, data)
}

//获取永久播放URL
export function getVideoUrl (data) {
  const url = '/video/getVideoUrl'
  return $axios.fVideoGet(url, data)
}

//重新上传到百家云
export function reUpload (data) {
  const url = '/video/reUpload'
  return $axios.fVideoGet(url, data)
}
