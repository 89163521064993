<template>
  <iframe id='pptiframe'  allowfullscreen='true' class="iframes" :src="iframeWinPaht" frameborder="0" ref="iframes" />
  <!-- <iframe
    allowfullscreen='true'
    class="iframes"
    src="http://localhost:8080/ppt/#/look"
    frameborder="0"
    ref="iframes"
  /> -->
</template>
<script>
import {getVideoUrl} from '@/api/video/videomanages'
export default {
  name:"Looks",
  data() {
    return {
      iframeWinPaht: process.env.VUE_APP_PPT_IDE + "look",
      pptData: {
        code: '可达鸭编程-'+localStorage.getItem('userName')+'|'+localStorage.getItem('pptToken'),
        userId: localStorage.getItem("userId"),
        token: localStorage.getItem('pptToken'),
        platform: localStorage.getItem("platform"), //id 1	merchant	可达鸭商户平台 id 2	tms	可达鸭平台
        currentTaskId: "",
        resourceId: "",
        courseId: "",
        courseName: "",
        unitId: "",
        unitName: "",
        sort: 0,
        taskList: [], //编辑时可为空
      },
    };
  },
  mounted() {
    window.addEventListener("message", this.handleMessage, true);
    let loginName = localStorage.getItem('userName')
    this.$watermark.setCourseWare('可达鸭编程-'+loginName);
  },
  beforeDestroy() {
    window.removeEventListener("message", this.handleMessage, true);
  },
  created() {
    const currentCourseInfo = JSON.parse(
      localStorage.getItem("currentCourseInfo")
    );
    // const currentTaskInfo = JSON.parse(localStorage.getItem("currentTaskInfo"));
    const currentAttachment = JSON.parse(localStorage.getItem("currentAttachment"));
    // console.log(currentTaskInfo);
    this.pptData = {
      ...this.pptData,
      content: this.$route.query.content,
      currentTaskId: this.$route.query.id,
      currentTaskName: this.$route.query.title,
      resourceId: this.$route.query.sourceId,
      courseId: this.$route.query.courseId,
      courseName: this.$route.query.courseName,
      unitId: this.$route.query.courseUnitId,
      unitName: this.$route.query.courseUnitTitle,
      sort: this.$route.query.sort,
      taskList: [
          {
                taskId: this.$route.query.id,
                taskName: this.$route.query.title,
                resourceId: this.$route.query.content,
            }
        ], //编辑时可为空,
    };
  },
  methods: {
    handleMessage(e) {
      let pptDom = document.getElementById('pptiframe').contentWindow
      switch (e.data.type) {
        case "setUseWareId":
          this.$emit("setUseWareId",e.data.data.id);
          break;
        case "getData":
          pptDom.postMessage(
            { data: this.pptData, type: "initData" },
            "*"
          );
          break;
        case "getPptVideoInfo":
           getVideoUrl({fileId:e.data.data.id.split('-')[1]}).then(res=>{
              pptDom.postMessage(
            { data: {
              ...res,
              id:e.data.data.id,
              mode:'look',
              code: '可达鸭编程-'+localStorage.getItem('userName')+'|'+localStorage.getItem('pptToken'),
              }, type: "returnPptVideoInfo" },
            "*"
            );
           })
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang='scss'>
.iframes {
  width: 100%;
  height: 100%;
  min-height: 600px;
  overflow: hidden;
}
#app{
  overflow: hidden;
}
</style>
